<template>
  <main>
    <!-- The blue bar - displayed only in non-iframe mode -->
    <div v-if="!iframe" class="absolute -z-1 mt-[34rem] hidden h-[448px] w-full bg-[#D7F2FF] lg:block"></div>

    <Blocks :blocks="blocks" class="mb-20 flex flex-col gap-12" />

    <StartNewsletterSignup v-if="!iframe" />
  </main>
</template>

<script setup lang="ts">
const newlyRendered = ref(true)

const iframe = computed(() => useParams().iframe)
const menuToggle = computed(() => useToggler().toggles('menu'))
const blocks = computed(() => useConfdata().body ?? [])

onMounted(async () => {
  await nextTick(() => {
    // Site gets newly rendered -> so call the navigateToAnchor function
    newlyRendered.value = true
    navigateToAnchor()
  })
})

watch(menuToggle, (newValue) => {
  if (!newValue) {
    // Check if the site was newly rendered
    if (!newlyRendered.value) {
      // Scroll to anchor
      navigateToAnchor()
    }
  }
})

const md = useScreen().breakpoints.smaller('md')

function navigateToAnchor() {
  nextTick(() => {
    // Check if the device is a mobile device via the window width
    if (md.value || useUseragent().useragent?.browser.name?.includes('Safari')) {
      // Because of the animation in the mobile menu we need to check the route and wait for the animation to finish
      if (useRoute().hash) {
        // Get hash without the hash
        const hashSplit = useRoute().hash.split('#')
        const hashRef = hashSplit[1]

        // Scroll to section with id
        const element = document.getElementById(hashRef)
        if (element) {
          element.scrollIntoView()
        } else {
          console.log('Element not found')
        }

        newlyRendered.value = false
      }
    }
  })
}
</script>
