<template>
  <section ref="hitboxGallery" :key="compKey" class="group relative w-full overflow-hidden">
    <NuxtImg
      v-if="!showSlider"
      class="w-full rounded-tl rounded-tr object-cover md:rounded-tr-none"
      loading="lazy"
      format="webp"
      placeholder
      :src="firstImage.src"
      :alt="firstImage.alt"
      :title="firstImage.title"
      @click="handleClick"
    />

    <LazyImageSlider
      v-else
      class="overflow-hidden rounded-tl rounded-tr md:rounded-tr-none"
      :images="images"
      :cover-full="coverFull"
      image-height="auto"
      :initial-direction="direction"
      @click="handleClick"
      @slide-change="useTracking().handlers?.detail.galleryNavigation()"
    />
    <slot />
    <button v-if="!showSlider" class="absolute left-0 top-0 h-full pl-4" @mouseover="loadImageKeys()" @click="loadSlider('prev')">
      <div
        class="rounded-lg bg-bgr bg-opacity-80 text-txt backdrop-blur-md transition-all duration-200 hover:scale-105 hover:bg-opacity-90 hover:shadow-md active:scale-100 group-hover:opacity-100 md:opacity-0 p-1"
      >
        <WebccIcon name="site/chevron-left" class="h-6 w-6 text-txt" filled />
      </div>
    </button>
    <button v-if="!showSlider" class="absolute right-0 top-0 h-full pr-4" @mouseover="loadImageKeys()" @click="loadSlider('next')">
      <div
        class="rounded-lg bg-bgr bg-opacity-80 text-txt backdrop-blur-md transition-all duration-200 hover:scale-105 hover:bg-opacity-90 hover:shadow-md active:scale-100 group-hover:opacity-100 md:opacity-0 p-1"
      >
        <WebccIcon name="site/chevron-right" class="h-6 w-6 text-txt" filled />
      </div>
    </button>
    <div class="absolute flex h-full w-full items-center justify-center">
      <NuxtImg v-if="!$params.iframe" :src="`logos/${useConfdata().company}/logo.svg`" class="w-1/2" />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { SliderImage } from '../info/ImageSlider.vue'

const trPrefix = 'www.components.hitbox.Gallery.'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    accom: ResultDocument
    checkin?: Date
    coverFull?: boolean
    transformations?: Record<string, string>
  }>(),
  {
    checkin: undefined,
    transformations: () => {
      return { xl: 'tr:n-srp_hitbox_sm', lg: 'tr:n-srp_hitbox_sm', md: 'tr:n-srp_hitbox_l' }
    },
  },
)

const emit = defineEmits<{ (e: 'open'): void }>()

const direction = ref('')
const showSlider = ref(false)
const images: Ref<SliderImage[]> = ref([])
const screen = useScreen()
const loading = ref(false)
const promise: Ref<Promise<string[]> | null> = ref(null)

const hitboxGallery: Ref<HTMLElement | null> = ref(null)

const imageTransformation = computed(() => {
  return props.transformations[screen.current] || 'tr:n-srp_hitbox_xl'
})
const compKey = computed(() => {
  return new Date().toISOString() + screen.current
})
const firstImage = computed(() => {
  const image = props.accom.image?.id
  const title = props.accom.image?.type ? t(`${trPrefix}${props.accom.image.type}`) : ''
  const alt = props.accom.slug ? `${title}|${props.accom.name}|${props.accom.region}|${props.accom.place}` : props.accom.code
  const src = `${useRuntimeConfig().public.imagekitBase}/${image}/${imageTransformation.value}`
  return { src, alt, title }
})

const mobile = ref(useUseragent().useragent?.device === 'mobile')

const season = computed(() => useSeason(props.checkin))

watch(props.accom, () => {
  showSlider.value = false
  images.value = []
})

onMounted(() => {
  // Check if the browser is mobile
  if (mobile.value) {
    // Load intersection observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            if (promise.value) {
              await promise.value
            } else {
              await loadImageKeys()
            }
            loadSlider('')
            // Stop observing
            observer.unobserve(entry.target)
          }
        })
      },
      {
        root: null,
        rootMargin: '200px 0px',
        threshold: 0.0,
      },
    )

    if (hitboxGallery.value) {
      observer.observe(hitboxGallery.value)
    }
  }
})

async function loadImageKeys() {
  if (images.value.length === 0 && !loading.value) {
    loading.value = true
    promise.value = useAccommodation(props.accom.code).getImages(season.value)
    try {
      const data = await promise.value
      promise.value = null
      if (!data) {
        return
      }
      const loadedImages: SliderImage[] = []
      const title = props.accom.image?.type ? t(`${trPrefix}${props.accom.image.type}`) : ''
      const alt = props.accom.slug ? `${title}|${props.accom.name}|${props.accom.region}|${props.accom.place}` : props.accom.code
      data.forEach((image: string) => {
        loadedImages.push({
          id: image,
          alt,
          title,
          src: '',
        })
      })
      images.value = loadedImages
      createLinks()
    } catch (error) {
      console.error('could not load images', error)
    }
    loading.value = false
  }
}

function createLinks() {
  images.value.forEach((image) => {
    image.src = `${useRuntimeConfig().public.imagekitBase}/${image.id}/${imageTransformation.value}`
  })
}

async function loadSlider(d: string) {
  if (promise.value) {
    await promise.value
  }
  if (d !== '') {
    direction.value = d
  }
  if (images.value.length > 0) {
    showSlider.value = true
  }
}

function handleClick() {
  emit('open')
}
</script>
