import type { RouteLocationNormalized } from 'vue-router'
import { withQuery } from 'ufo'

export const useConfdataStore = defineStore('confdata', () => {
  const l10n = useL10N()

  const currentUrl = ref<string>()
  const raw: Ref<ConfAPIPagesResponse | null> = ref(null)
  const company: Ref<Company> = computed(() => raw.value?.conf?.company as 'ic' | 'ih')
  const domain: Ref<Domain> = computed(() => raw.value?.conf?.domain as Domain)
  const domainInEnvironment: Ref<string> = computed(() => raw.value?.conf?.domainInEnvironment as string)
  const baseData = computed(() => raw.value?.baseData)
  const currencies = computed(() => raw.value?.conf?.currencies)
  const currency = computed(() => useParams().currency || raw.value?.conf?.currencies[0] || 'EUR')
  const pagetype = computed(() => raw.value?.pagetype)
  const salesoffice = computed(() => raw.value?.conf?.salesoffice)
  const siteSettings = computed(() => raw.value?.conf?.settings)
  const paymentForms = computed(() => raw.value?.conf?.salesoffice.paymentForms)
  const siteCode = computed(() => raw.value?.conf?.siteCode)

  const header = computed(() => raw.value?.conf?.header)
  const footer = computed(() => raw.value?.conf?.footer)
  const links = computed(() => raw.value?.conf?.links)
  const additionalBody = computed(() => raw.value?.additionalBody ?? {})
  const page = computed(() => raw.value?.page)
  const body = computed(() => raw.value?.page.body)
  const seoLinks = computed(() => raw.value?.seoLinks)

  const translations = computed(() => raw.value?.translations)
  const languagePrefix = computed(() => raw.value?.languageprefix)
  const locale = computed(() => raw.value?.locale)
  const language = computed(() => raw.value?.language)
  const domainsCountryCode = computed(() => raw.value?.conf?.domainsCountryCode)
  const destination = computed(() => {
    const result = { ...raw.value?.destination }
    if (result.regionCode) {
      result.regionCodeFull = result.countryCode + result.regionCode
    }
    if (result.placeCode) {
      result.placeCodeFull = result.countryCode + result.placeCode
    }
    return result
  })
  const sites = computed(() => raw.value?.conf?.sites ?? [])
  const logoCaption = computed(() => raw.value?.conf?.logoCaption)
  const pageID = computed(() => raw.value?.pageID)
  const prefixSearchpage = computed(() => raw.value?.conf?.prefixSearchpage)
  const prefixReviewspage = computed(() => raw.value?.conf?.prefixReviewspage)
  const bodyComponentsByName = computed(() => {
    const components = {} as { [key: string]: Block }
    body.value?.forEach((component) => {
      components[component.component] = component
    })
    return components
  })

  const signupEnabled = computed(() => !useRuntimeConfig().public.signup.disabledDomains.some((domain) => siteCode.value?.includes(domain)))
  const cancelConditions = computed(() => (additionalBody.value as SearchPageBody)?.cancelConditions ?? [])
  const freeCancellationRange = computed(() => getFreeCancellationRange(cancelConditions.value))

  const head = computed(() => {
    if (!page.value || !seoLinks.value) return undefined

    const { meta, title, description } = page.value
    const image = meta.image || `/logos/${company.value}/logo.svg`
    const viewport = `width=device-width, initial-scale=1${useUseragent().useragent?.browser.name === 'Safari' ? ', maximum-scale=1' : ''}`
    const canonical = { rel: seoLinks.value.canonical.rel, href: new URL(seoLinks.value.canonical.href).toString() }
    const ogURL = meta.url ?? canonical.href
    const additional = meta.additional?.ogurl ? {} : (meta.additional ?? {})
    const companyPath = `/${getCompanySlug(company.value)}`

    return {
      title,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: viewport },
        { name: 'robots', content: page.value.robots, hid: 'robots' },
        { name: 'language', content: locale.value },
        { hid: 'description', name: 'description', content: description },
        { hid: 'og:image', property: 'og:image', content: image },
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'og:description', property: 'og:description', content: description },
        { hid: 'og:type', property: 'og:type', content: meta.type },
        { hid: 'og:url', property: 'og:url', content: ogURL },
        { hid: 'og:locale', property: 'og:locale', content: locale.value },
        ...Object.entries(additional).map(([key, value]) => ({ hid: key, property: key, content: value?.toString() })),
      ],
      link: [
        { rel: 'preconnect', href: 'https://www.google-analytics.com' },
        { rel: 'preconnect', href: 'https://www.googletagmanager.com' },
        { rel: 'preconnect', href: 'https://cdn.trkkn.com' },
        { rel: 'preconnect', href: 'https://webcc.interhome.group' },
        { rel: 'icon', type: 'image/x-icon', href: `${companyPath}/favicon.ico` },
        { rel: 'apple-touch-icon', sizes: '180*180', href: `${companyPath}/apple-touch-icon.png` },
        { rel: 'icon', type: 'image/png', sizes: '32*32', href: `${companyPath}/favicon-32x32.png` },
        { rel: 'icon', type: 'image/png', sizes: '16*16', href: `${companyPath}/favicon-16x16.png` },
        { rel: 'shortcut icon', sizes: '16*16', href: `${companyPath}/favicon-16x16.png` },
        // { rel: 'manifest', href: `/${company}/manifest.json` },
        { rel: 'mask-icon', href: `${companyPath}/safari-pinned-tab.svg` },
        canonical,
        ...seoLinks.value.alternates,
      ],
    }
  })

  async function load(to?: RouteLocationNormalized) {
    const url = to ? new URL(to.fullPath, useRequestURL().origin) : useRequestURL()

    if (url.toString() === currentUrl.value) return

    currentUrl.value = url.toString()

    // Keep `partnerid` to get the correct cancellation conditions, otherwise the default conditions will be returned.
    const params: Params = { partnerid: usePartner().id || undefined }

    // Language will be handled for redirection
    const query = Object.fromEntries(url.searchParams)
    url.searchParams.delete('language')

    const { data, error } = await useConfApi().page(url, params)

    if (error.value) return error.value

    if (data.value?.pagetype === 'redirect') return data.value.body

    if (data.value?.pagetype === 'search') {
      if (!(data.value.additionalBody as SearchPageBody).searchfilterconfs) {
        ;(data.value.additionalBody as SearchPageBody).searchfilterconfs = (additionalBody.value as SearchPageBody).searchfilterconfs
      }
      raw.value = data.value && { ...raw.value, ...data.value }
    } else {
      raw.value = data.value ?? null
    }

    const pageTranslations = raw.value?.translations

    // update translations on confData load
    if (pageTranslations) {
      l10n.setPageTranslations(pageTranslations, raw.value!.locale, raw.value!.language)
    }

    if (query.language && query.language !== data.value?.language) {
      const newPath = getLocalizedUrl(query.language) ?? getLocalizedUrl(sites.value[0]?.language) ?? ''
      const newQuery = { ...query, language: undefined }
      return { location: withQuery(newPath, newQuery) }
    }
  }

  function getLocalizedUrl(lang: string) {
    const localeUrl = footer.value?.localeURLs[company.value + domainsCountryCode.value + lang]

    return localeUrl ? getUrlFullPath(localeUrl) : undefined
  }

  function isCanonicalPage(url: URL) {
    const canonicalUrl = seoLinks.value?.canonical.href
    // Ignore `partnerid` param detecting canonical page because the partnerid is just an info within in the url and
    // should not affect the canonical page detection.
    return !!canonicalUrl && getUrlFullPath(removeUrlParams(url, 'partnerid')) === getUrlFullPath(canonicalUrl)
  }

  return {
    load,
    getLocalizedUrl,
    isCanonicalPage,
    raw,
    baseData,
    company,
    domain,
    domainInEnvironment,
    currencies,
    currency,
    pagetype,
    salesoffice,
    siteSettings,
    paymentForms,
    siteCode,
    header,
    footer,
    links,
    additionalBody,
    page,
    body,
    seoLinks,
    translations,
    languagePrefix,
    locale,
    language,
    domainsCountryCode,
    destination,
    sites,
    logoCaption,
    pageID,
    prefixSearchpage,
    prefixReviewspage,
    bodyComponentsByName,
    signupEnabled,
    cancelConditions,
    freeCancellationRange,
    head,
  }
})
