<template>
  <client-only>
    <Teleport v-if="useStorageReady()" to="body">
      <div id="consent" class="relative flex items-center justify-center text-base">
        <ConsentConfig v-if="useConsent().configOpen" @close="closeConfig" @save-choice="saveChoice" />
        <ConsentBanner
          v-if="useConsent().open"
          :open-config="openConfig"
          :open-privacypolicy="openPrivacypolicy"
          :config-is-open="useConsent().configOpen"
          @save-choice="saveChoice"
        />
      </div>
    </Teleport>
  </client-only>
</template>

<script setup lang="ts">
const decision = ref(false)

defineExpose({ decision, openConfig, openPrivacypolicy })

function openPrivacypolicy() {
  window.location.href = useConfdata().links?.privacyPolicy?.href || 'https://www.interhome.group/privacy-policy'
}

function openConfig() {
  useConsent().configOpen = true
}

function closeConfig() {
  useConsent().configOpen = false
}

function saveChoice(options: Choice) {
  useConsent().configOpen = false
  useConsent().update({
    ...options,
    timestamp: new Date(),
    initDecision: useConsent().consent.initDecision === null,
  })
}
</script>
