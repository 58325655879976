<template>
  <div class="flex flex-wrap items-start md:flex-nowrap md:justify-between">
    <div class="flex grow justify-between sm:justify-start md:w-3/5">
      <div class="flex grow items-center font-bold sm:w-1/2 sm:grow-0 md:w-2/3 md:grow md:font-normal">
        <label
          v-if="service.name && service.name.length > 0"
          class="text-txt"
          :class="{ 'cursor-help': tooltip }"
          :for="service.code"
          @mouseenter="showTooltip(service.code)"
          @mouseleave="hideTooltip(service.code)"
        >
          <span class="mr-2">{{ service.name }}</span>
          <WebccIcon
            v-if="service.description && service.description.length > 0"
            :id="service.code"
            v-tooltip="{ content: !tooltip ? '' : tooltip, html: true }"
            name="site/info"
            class="mb-[3px] inline h-4 w-4 text-thm"
            :class="{ 'cursor-help': tooltip }"
          />
        </label>

        <div v-if="service.validFrom && service.validTo" class="ml-2">({{ formatDatetime(service.validFrom) }} - {{ formatDatetime(service.validTo) }})</div>
        <div v-else-if="service.validFrom" class="ml-2">
          (<span>{{ $t(`${trPrefix}from`) }}</span> {{ formatDatetime(service.validFrom) }})
        </div>
        <div v-else-if="service.validTo" class="ml-2">
          (<span>{{ $t(`${trPrefix}to`) }}</span> {{ formatDatetime(service.validTo) }})
        </div>
      </div>

      <div class="items-center text-right font-semibold sm:w-1/4 md:mx-6 md:font-normal">
        {{ service.priceBase ? formatCurrency(service.priceBase, '2-digits', service.currency) : '' }}
      </div>
    </div>
    <div class="flex w-full flex-wrap items-center md:w-2/5">
      <div v-if="service.code === 'INSZ'">
        <p>{{ $t(`${trPrefix}inszRecommendation`) }}</p>
        <WebccLink anchor class="text-thm" :to="inszLink" target="_blank"
          ><span>{{ $t(`${trPrefix}inszInformation`) }}</span></WebccLink
        >
      </div>
      <div v-else-if="service.code === 'CO2'">
        <p>{{ service.paymentInfo }}</p>
        <WebccIcon name="detailpage/hearttree" class="inline-flex align-middle h-4 w-4 text-suc" filled />
        <span>&nbsp;</span>
        <WebccLink anchor class="text-thm" :to="co2Link" target="_blank"
          ><span>{{ $t(`${trPrefix}co2Information`) }}</span></WebccLink
        >
      </div>
      <div v-else-if="service.code === 'SUSU'">
        <p>{{ service.paymentInfo }}</p>
        <WebccLink anchor class="text-thm" :to="susu2Link" target="_blank"
          ><span>{{ $t(`${trPrefix}susuInformation`) }}</span></WebccLink
        >
      </div>
      <div v-else>{{ service.paymentInfo }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
const tracking = useTracking()
const trPrefix = 'www.components.views.details.Services.'

const props = defineProps<{
  service: Service
  links: Links
}>()

const inszLink = computed(() => {
  return props.links.travelInsurance?.href
})

const co2Link = computed(() => {
  return props.links.sustainability?.href
})
// laut HHDWBCC-4427/4429 zunächst auch auf sustainability
const susu2Link = computed(() => {
  return props.links.sustainability?.href
})
const tooltip = computed(() => {
  if (!props.service.description) {
    return ''
  }
  const tt = props.service.description.replace(/•/g, '<br />•').replace(/ %/g, '&nbsp;%')
  return tt
})

function showTooltip(id: string) {
  if (document.getElementById(id)) {
    const mouseenterEvent = new Event('mouseenter')
    document.getElementById(id)?.dispatchEvent(mouseenterEvent)
    tracking.handlers?.detail.mouseoverCostoverviewInfo(props.service.code)
  }
}

function hideTooltip(id: string) {
  if (document.getElementById(id)) {
    const mouseleaveEvent = new Event('mouseleave')
    document.getElementById(id)?.dispatchEvent(mouseleaveEvent)
  }
}
</script>
