<template>
  <div class="root" :class="[company]">
    <DetailsMonthlyPrices
      v-if="useAB().isActive('monthlyPrices')"
      :accom="accomCode"
      :current="current"
      :duration="duration"
      :pax="modelValue?.pax || 1"
      :vacancies="vacancies.months"
      :locale="locale"
      @monthclick="gotoMonth"
    />

    <header class="pb-4 block md:hidden">
      <DetailsPaxSelector v-model="pax" class="w-48" :max="paxUpTo" :prefix="'www.components.views.details.MobileBar.'" @click.stop />
    </header>

    <div class="relative rounded bg-bgr p-4" @click.stop>
      <header class="hidden md:flex mt-6 flex-wrap items-center justify-between border-b pb-3">
        <h3 class="hidden text-base font-bold text-txt md:block">{{ $t(`${trPrefix}headline.${mode}`) }}</h3>
        <!-- TODO: Verhalten nach einer Auswahl verbessern -> Wert anzeigen, Änderungen ermöglichen -->
        <WebccSelect
          v-model="duration"
          name="duration"
          :options="durationOptions"
          :disabled="!durationOptions.length"
          size="sm"
          class="min-w-1/4"
          @click="onDurationOpen"
        />
      </header>

      <main class="flex w-full justify-center py-2">
        <client-only>
          <WebccDateRangepicker ref="calendar" v-model="range" :locale="locale" :vacancies="vacancies" @update-page="onUpdatePageDates" />
        </client-only>
        <!-- <client-only>
          <WebccTripPicker ref="calendar" v-model="range" inline :locale="locale" :vacancies="vacancies" @update-page="onUpdatePageDates" />
        </client-only> -->
      </main>

      <footer v-track:impression.20="'detail:calendarImpression'" class="hidden md:block mt-2 border-t pt-3">
        <h4 class="mb-4 text-base font-bold text-txt">{{ $t(`${trPrefix}legend.title`) }}</h4>
        <ul class="grid w-full gap-4" style="grid-template-columns: repeat(auto-fill, minmax(350px, 1fr))">
          <li class="flex flex-row items-center justify-start gap-2">
            <div class="flex h-8 w-8 flex-row items-center justify-center rounded-sm !border-0 bg-suc text-[14px] font-semibold">11</div>
            <span>{{ $t(`${trPrefix}legend.${mode}`) }}</span>
          </li>
          <li class="flex flex-row items-center justify-start gap-2">
            <div
              class="relative flex h-8 w-8 flex-row items-center justify-center rounded-sm !border-0 bg-suc p-1 text-[14px] font-semibold before:absolute before:-right-px before:-top-px before:flex before:h-4 before:w-4 before:items-center before:justify-center before:rounded-[50%] before:bg-hlt before:text-xs before:text-bgr before:content-['%']"
            >
              11
            </div>
            <span>{{ $t(`${trPrefix}legend.discount`) }}</span>
          </li>
          <li class="flex flex-row items-center justify-start gap-2">
            <div class="flex h-8 w-8 flex-row items-center justify-center rounded-sm !border-0 bg-suc-reduced text-[14px] font-semibold">11</div>
            <span>{{ $t(`${trPrefix}legend.no${mode}`) }}</span>
          </li>
          <li class="flex flex-row items-center justify-start gap-2">
            <div class="flex h-8 w-8 flex-row items-center justify-center rounded-sm !border-0 text-[14px] font-semibold line-through opacity-30">11</div>
            <span>{{ $t(`${trPrefix}legend.disabled`) }}</span>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import type WebccTripPicker from '@/components/@ihgroup/components/WebccTripPicker.vue'

const trPrefix = 'www.components.views.details.Vacancy.'

const { t } = useI18n()

interface Props {
  modelValue: TripUpdate | null
  accomCode?: string
  vacancies: Trips
  paxUpTo: number
}

interface SelectionMonth {
  year: number
  month: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  accomCode: '',
  vacancies: () => ({}) as Trips,
  paxUpTo: 1,
})

const emit = defineEmits<{
  'update:modelValue': [TripUpdate]
}>()

const monthFrom: Ref<SelectionMonth | null> = ref(null)
const monthTo: Ref<SelectionMonth | null> = ref(null)
const arrivalSelected = ref(false)
const calendar: Ref<typeof WebccTripPicker | null> = ref(null)

const locale = computed(() => useL10N().locale)
const company = computed(() => useConfdata().company)
const mode = computed(() => (arrivalSelected.value ? 'departure' : 'arrival'))

const pax = computed({
  get() {
    return props.modelValue?.pax || props.paxUpTo
  },
  set(value) {
    emit('update:modelValue', { pax: parseInt(value.toString()) })
  },
})

const range = computed({
  get() {
    const { checkin, checkout } = props.modelValue ?? {}
    const [start, end] = [checkin, checkout].map(tryParseDate)

    return { start, end }
  },
  set(range) {
    useTracking().handlers?.detail.datepickerInteraction('dateSelected')

    if (!range) return
    if (!range.start || !range.end) return console.error('Invalid range', range)

    const [checkin, checkout] = [range.start, range.end]
    emit('update:modelValue', { checkin, checkout })
    arrivalSelected.value = false
  },
})

const duration = computed({
  get() {
    return getTripDuration(props.modelValue ?? {})
  },
  set(value) {
    if (!range.value.start) return

    emit('update:modelValue', { checkout: calculateCheckoutDate(range.value.start, value) })
    useTracking().handlers?.detail.calendarDurationSelect(value)
  },
})

const durationOptions = computed(() => {
  const duration = useVacancyDays(props.vacancies.days, ref(range.value.start)).getDurations()
  return duration.map((value) => ({ value, label: t(`${trPrefix}night_s`, value) }))
})

const current = computed(() => {
  const from = new Date(props.vacancies.dateFrom)
  const to = new Date(props.vacancies.dateTo)
  const foo = {
    first: {
      year: from.getFullYear(),
      month: from.getMonth() + 1,
      index: from.getFullYear() * 12 + from.getMonth() + 1,
    },
    current: Object.assign(monthFrom.value ?? {}, {
      index: (monthFrom.value?.year ?? 0) * 12 + (monthFrom.value?.month ?? 0),
    }),
    last: {
      year: to.getFullYear(),
      month: to.getMonth() + 1,
      index: to.getFullYear() * 12 + to.getMonth() + 1,
    },
  }
  return foo.current.index - foo.first.index
})

function onDurationOpen() {
  // TODO: check, warum @click="onDurationOpen" auch bei onDurationChange getriggert wird
  useTracking().handlers?.detail.calendarDurationOpened()
}

function onUpdatePageDates(from: SelectionMonth, to: SelectionMonth) {
  monthFrom.value = from
  monthTo.value = to
  console.log('onUpdatePageDates', from.month, to.month)
}

function gotoMonth(month: PriceMonth) {
  useTracking().handlers?.detail.monthlyPricesSelect({ formatted: month.formatted })
  // const calendar = this.$refs.calendar
  // calendar.value?.gotoMonth(month)
}
</script>
