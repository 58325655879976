import clamp from 'lodash-es/clamp'

export const RATING_MAX = 5
export type RoundedRating = 1 | 2 | 3 | 4 | 5

export function formatRating(rating: number): string {
  return formatNumber(rating, '1-digit')
}

export function roundRating(rating: number): RoundedRating {
  return clamp(Math.round(rating), 1, 5) as RoundedRating
}
