export function tryParseInt(value?: string): number | undefined {
  const result = value ? parseInt(value) : NaN
  return !isNaN(result) ? result : undefined
}

export function tryParseFloat<Value extends string | number | undefined | null, Result extends [Value] extends [number] ? number : number | undefined>(
  value?: Value,
): Result {
  const result = typeof value === 'number' ? value : value ? parseFloat(String(value)) : NaN
  return (!isNaN(result) ? result : undefined) as Result
}
