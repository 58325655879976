<template>
  <div class="flex flex-row flex-wrap items-center justify-end gap-x-2 text-sm font-bold text-thm">
    <span v-if="overallRating > 0">{{ $t(`${trPrefix}labels.${roundedRating}`) }}</span>
    <RatingHearts :rating="overallRating" :title="overallRating + ' / 5'" color="theme" size="3" />
    <span class="flex flex-row justify-end text-xs text-txt">{{ $t(`${trPrefix}review_s`, reviewCount) }}</span>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Rating.'

const props = defineProps<{
  code: string
  overallRating: number
  reviewCount: number
}>()

const roundedRating = computed(() => roundRating(props.overallRating))
</script>
ƒ
