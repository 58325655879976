export function useAccommodation(code: string) {
  async function getImages(season: Season) {
    return (await useWebccApi().accommodationImages(code, season)).data.value ?? []
  }

  async function getRating() {
    return (await useWebccApi().accommodationReviews(code)).data.value
  }

  async function getVacancies() {
    const { data } = await useWebccApi().accommodationVacancies(code, {
      currency: useConfdata().currency,
      maxage: useRuntimeConfig().public.maxage.vacancies,
    })

    return data.value ?? undefined
  }

  return { getImages, getVacancies, getRating }
}
