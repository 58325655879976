<template>
  <section v-if="useL10N().language !== 'de' && !$params.iframe" class="relative bottom-0 mt-12 rounded bg-bgr px-9 pb-4 pt-0 shadow">
    <header
      class="left-[10%] right-[10%] -mb-2 inline-table w-full -translate-y-1/2 cursor-default whitespace-normal rounded bg-thm p-1 text-center text-sm font-normal text-txt-contrast"
    >
      <span>{{ $t(`${trPrefix}furtherQuestions`) }}</span>
      <br />
      <small class="text-txt-contrast">{{ $t(`${trPrefix}24_7`) }}</small>
    </header>

    <p class="font-bold">{{ $t(`${trPrefix}contactUs`) }}</p>

    <p class="my-2">
      <WebccLink
        anchor
        class="flex items-center text-xl font-bold text-thm"
        :to="'tel:' + phone.replace(' ', '')"
        @click="useTracking().handlers?.detail.contactClick('Phone')"
      >
        <WebccIcon name="detailpage/phone-duplicate-telefon" class="mr-3 inline-block h-6 w-6" />
        {{ phone }}
      </WebccLink>
    </p>
    <p class="my-2">
      <WebccLink anchor class="flex items-center text-base text-thm" :to="'mailto:' + email" @click="useTracking().handlers?.detail.contactClick('Email')">
        <WebccIcon name="detailpage/mail-duplicate" class="mr-3 inline-block h-6 w-6" />
        {{ email }}
      </WebccLink>
    </p>

    <p class="my-3 text-sm">{{ $t(`${trPrefix}counsel`) }}</p>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.SidebarContactCard.'

defineProps<{
  phone: string
  email: string
}>()

// const generalRequestExists = computed(() => {
//   return props.generalRequestUrl && props.generalRequestUrl.substring(0, 1) !== '?'
// })
</script>
