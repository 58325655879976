import type { IncomingMessage } from 'http'
import type { Context, Unleash, Variant } from 'unleash-client'
import { getPublicDomainName } from '~/utils/domain'
import { getLanguagePrefix } from '~/utils/urls'

export const PERSISTED_UNLEASH_STATE = '__unleash' as const

export function getUnleashDataForContext(client: Unleash, userCtx: Context) {
  const features: Record<string, unknown> = {}
  const payloads: Record<string, unknown> = {}
  client.getFeatureToggleDefinitions().forEach((toggleDefinition: { name: any }) => {
    const { name } = toggleDefinition
    const isEnabled = client.isEnabled(name, userCtx)
    const variant = client.getVariant(name, userCtx)
    features[name] = isEnabled && (variant?.enabled ? variant.name : isEnabled)
    if (variant?.enabled) {
      payloads[name] = getVariantPayloadValue(variant)
    }
  })

  return {
    features,
    payloads,
  }
}

export function getVariantPayloadValue(variant: Variant): string | number | object | null {
  if (!variant.payload) return null
  const { type, value } = variant.payload
  if (type === 'json') return JSON.parse(value)
  if (type === 'number') return Number(value)
  return value
}

export function getUnleashContextFromRequest(req: IncomingMessage) {
  const domain = getPublicDomainName(req.headers.host ?? '')
  return {
    userId: getUserId(req),
    domain,
    remoteAddress: getRemoteAddress(req),
    properties: {
      language: getUserLanguage(req, domain ?? '').toUpperCase(),
      fullDomain: `${domain}${getLanguagePrefix(req.url ?? '')}`,
    },
  }
}

export function getUserId(req: IncomingMessage) {
  return getRequestCookies(req)['User-ID']
}

export function getRemoteAddress(req: IncomingMessage) {
  return (req.headers['x-real-ip'] as string) || (req.headers['x-forwarded-for'] as string) || req.socket.remoteAddress || req.connection.remoteAddress
}

const DEFAULT_LANGUAGE = 'en'
const TLD_TO_LANGUAGE: Record<string, string> = {
  be: 'nl',
  cz: 'cs',
  dk: 'da',
  de: 'de',
  es: 'es',
  ee: 'en',
  fr: 'fr',
  uk: 'en',
  hr: 'hr',
  ie: 'en',
  it: 'it',
  nl: 'nl',
  no: 'no',
  at: 'de',
  pl: 'pl',
  pt: 'pt',
  ch: 'de',
  fi: 'fi',
  se: 'sv',
  com: 'en',
  au: 'en',
  ca: 'en',
  in: 'en',
  us: 'en',
}

export function getUserLanguage(req: IncomingMessage, domain: string): string {
  try {
    const cookies = getRequestCookies(req)
    if ('i18n_redirected' in cookies && cookies.i18n_redirected.length >= 2) {
      return cookies.i18n_redirected.substr(0, 2).toLowerCase()
    }
  } catch (e) {
    console.error(`Unleash.utils getUserLanguage error`, e)
    // no throw
  }
  const prefix = getLanguagePrefix(req.url ?? '')
  const topLevelDomain = domain.split('.').pop() ?? ''
  return prefix.replaceAll('/', '') || TLD_TO_LANGUAGE[topLevelDomain] || DEFAULT_LANGUAGE
}

function getRequestCookies(req: IncomingMessage) {
  return Object.fromEntries(req?.headers.cookie?.split(';').map((c) => c.trim().split('=')) || [])
}
