<template>
  <div class="w-full h-fit border border-bgr-200 shadow-lg rounded flex px-4 py-3 sm:p-6 items-center gap-4">
    <div class="shrink-0 size-12 sm:size-14 md:size-16 xl:size-20 rounded-full overflow-hidden bg-thm flex items-center justify-center">
      <img v-if="avatarUrl" :src="avatarUrl" alt="" />
      <span v-else-if="initials" class="text-lg lg:text-2xl text-bgr">{{ initials }}</span>
      <WebccIcon v-else name="account/user" class="size-8 text-bgr" />
    </div>
    <div class="overflow-hidden">
      <span class="text-base sm:text-xl md:text-2xl">{{ name || $t(`${trPrefix}name`) }}</span>
      <div class="flex items-center text-txt-weak">
        <WebccIcon name="account/mail" class="size-4 text-txt-weak shrink-0 mr-2 hidden sm:flex" />
        <p class="text-sm sm:text-base truncate" :title="email">{{ email }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.site.Header.user.'

const { email, name, initials, avatarUrl } = storeToRefs(useUser())
</script>

<style scoped></style>
