<template>
  <div v-on-click-outside.bubble="onClickOutside" class="flex flex-col w-full bg-bgr md:rounded-lg md:border md:border-bgr-300 md:shadow-lg">
    <header class="relative flex flex-row items-center justify-center space-x-4 border-b border-bgr-300 p-4">
      <div
        class="cursor-pointer border-b duration-100 hover:text-thm-hover"
        :class="flexTrip.flexibleCalendar ? 'border-transparent text-txt-weak' : 'border-thm text-thm'"
        @click.stop="switchFlexible(false)"
      >
        <span>{{ $t(`${trPrefixSearchDates}calendar`) }}</span>
      </div>
      <div
        class="cursor-pointer border-b duration-100 hover:text-thm-hover"
        :class="flexTrip.flexibleCalendar ? 'border-thm text-thm' : 'border-transparent text-txt-weak'"
        @click.stop="switchFlexible(true)"
      >
        <span>{{ $t(`${trPrefixSearchDates}flexible`) }}</span>
      </div>
      <div class="absolute right-5 top-5" @click="close">
        <WebccIcon name="site/x" class="h-6 w-6 text-txt-weak" />
      </div>
    </header>

    <main class="mt-2 mb-[.625rem] py-4 grow overflow-y-auto">
      <div v-if="flexTrip.flexibleCalendar">
        <h2 class="mb-2 text-center text-xl font-medium">{{ $t(`${trPrefixFlexibleDates}h_duration`) }}</h2>
        <div v-if="flexTrip.duration" class="mb-2 text-center text-thm">{{ flexTrip.durationText }}</div>

        <div
          class="mx-auto mb-4 grid w-fit max-w-[95%] grid-cols-1 items-center justify-items-center gap-4 sm:mb-6 sm:grid-cols-3 sm:gap-6 md:flex md:justify-center"
        >
          <WebccButton
            v-for="duration in presetDurations"
            :key="duration"
            class="h-11 w-36 rounded-lg"
            variant="grey"
            framed
            :class="flexTrip.duration === duration ? 'border-bgr-500 bg-bgr-400' : ''"
            @click="selectDuration(duration)"
          >
            <span class="font-medium">{{ flexTrip.getDurationText(duration) }}</span>
          </WebccButton>
          <select
            :value="!flexTrip.duration || presetDurations.includes(flexTrip.duration) ? 0 : flexTrip.duration"
            class="h-11 w-36 rounded-lg border border-bgr-300 bg-bgr-100 pr-8 font-medium hover:border-bgr-400 hover:bg-bgr-300 focus:bg-bgr-300 focus:outline-none focus:ring-2 focus:ring-thm focus:ring-offset-2"
            @input="selectDuration(+($event.target as HTMLInputElement).value)"
          >
            <option disabled hidden class="padding-x-4" selected value="0">{{ $t(`${trPrefixFlexibleDates}custom`) }}</option>
            <option v-for="i in DURATION_MAX" :key="i" class="padding-x-4 text-txt" :value="i">
              {{ $t(`${trPrefixFlexibleDates}night_s`, {}, i) }}
            </option>
          </select>
        </div>

        <h2 class="mb-2 text-center text-xl font-medium">{{ $t(`${trPrefixFlexibleDates}h_range`) }}</h2>

        <div class="mb-2 text-center text-thm">{{ $t(`${trPrefixFlexibleDates}tip_range`) }}</div>
      </div>

      <div class="flex flex-row justify-center">
        <WebccDateRangepicker
          :key="flexTrip.updateKey"
          v-model="flexTrip.range"
          :locale="$i18n.locale"
          :min-length="flexTrip.duration ?? DURATION_MIN"
          :max-length="flexTrip.flexibleCalendar ? DURATION_MAX_FLEX : DURATION_MAX"
          @select-start="trackArrival"
          @update:model-value="trackDeparture"
        />
      </div>
      <div v-if="mobile && !flexTrip.flexibleCalendar" class="px-4 w-full box-border text-center">
        <WebccDateRangePlusMinusDays class="mb-4" />
        <span class="font-semibold">{{ flexTrip.tripText }}</span>
      </div>
    </main>

    <footer class="w-screen pb-4 md:relative md:bottom-auto md:w-auto bg-white">
      <div class="flex flex-row items-center justify-between border-t border-bgr-300 p-4">
        <WebccButton variant="white" :disabled="!flexTrip.valid" @click="reset">
          <span class="font-semibold underline">{{ $t(`${trPrefixSearchDates}reset`) }}</span>
        </WebccButton>
        <WebccDateRangePlusMinusDays v-if="!mobile && !flexTrip.flexibleCalendar" />
        <WebccButton variant="theme" :disabled="!flexTrip.valid" @click="apply">
          <span class="font-semibold">{{ $t(`${trPrefixSearchDates}apply`) }}</span>
        </WebccButton>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'

const trPrefixSearchDates = 'www.components.elements.SearchDates.'
const trPrefixFlexibleDates = 'www.components.elements.FlexibleDates.'

const emit = defineEmits<{ close: [] }>()

const screen = useScreen()
const flexTrip = useFlexTrip()
const tracking = useTracking()

const mobile = computed(() => screen.isExtraSmall)
const presetDurations = computed(() => [7, 14])

function switchFlexible(flexible: boolean) {
  window.sessionStorage.setItem('flexible-search', flexible ? 'true' : 'false')
  flexTrip.flexibleCalendar = flexible
  if (flexible) {
    const duration = isDateRangeValid(flexTrip.range) ? calculateDuration(flexTrip.range.start, flexTrip.range.end) : undefined
    flexTrip.changeDuration(duration)
    flexTrip.fuzzyness = 0
  } else {
    nextTick(() => useSearch().setFilter({ duration: undefined }))
  }

  tracking.handlers?.search.datesModeSwitch(flexible)
}

function selectDuration(duration: number) {
  tracking.handlers?.search.datesDurationSelectFlex(duration)
  tracking.handlers?.homepage.datesDurationSelect(duration)
  flexTrip.changeDuration(duration)
}

function reset() {
  flexTrip.reset()
  tracking.handlers?.search.datesClear(flexTrip.flexibleCalendar)
}

function apply() {
  tracking.handlers?.search.datesApply(flexTrip.flexibleCalendar)
  close()
}

function close() {
  emit('close')
}

function onClickOutside() {
  if (screen.isSmall) return

  close()
}

function trackArrival(arrival?: Date) {
  if (!arrival) return

  tracking.handlers?.search.datesArrivalSelect(arrival, flexTrip.flexibleCalendar)
  tracking.handlers?.homepage.datesArrivalSelect(arrival)
}

function trackDeparture({ start, end }: DateRange) {
  if (!start || !end) return

  const duration = calculateDuration(start, end)
  tracking.handlers?.search.datesDurationSelect(duration, flexTrip.flexibleCalendar)
  tracking.handlers?.homepage.datesDurationSelect(flexTrip.flexDuration ?? duration)
}
</script>
