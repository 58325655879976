<template>
  <article class="overflow-hidden rounded bg-bgr shadow">
    <header class="flex flex-wrap items-center justify-between gap-2 bg-thm px-4 py-2 text-txt-contrast">
      <RatingHearts :title="rating + ' / 5'" :negative="true" :rating="rating" color="white" />
      <span class="mr-auto text-base font-bold">{{ $t(`${trPrefix}labels.${roundedRating}`) }}</span>
      <span>{{ $t(`${trPrefix}overallRating`) }}</span>
      <span class="pl-2 text-lg">{{ formatNumber(roundedRatingOneDecimal, '1-digit') }}<small class="opacity-50"> / 5</small></span>
    </header>

    <ul class="p-2 px-4 text-sm">
      <li v-if="condition" class="flex items-center gap-2 py-2">
        <RatingHearts class="text-thm" :title="roundedCondition + ' / 5'" :rating="condition" />
        <span>{{ $t(`${trPrefix}overallCondition`) }}</span>
      </li>
      <li v-if="cleanliness" class="flex items-center gap-2 py-2">
        <RatingHearts class="text-thm" :title="roundedCleanliness + ' / 5'" :rating="cleanliness" />
        <span>{{ $t(`${trPrefix}overallCleanliness`) }}</span>
      </li>
      <li v-if="service" class="flex items-center gap-2 py-2">
        <RatingHearts class="text-thm" :title="roundedService + ' / 5'" :rating="service" />
        <span>{{ $t(`${trPrefix}overallService`) }}</span>
      </li>
    </ul>
    <p class="text-center text-sm leading-10 text-txt">
      <span>{{ $t(`${trPrefix}basedOn_s`, reviewCount) }}</span>
    </p>
  </article>
  <!---Gesamtbewertung Ende--->
</template>

<!-- <script>
export default {
  name: 'ReviewSummary',
}
</script> -->

<script setup lang="ts">
const trPrefix = 'www.components.views.reviews.Summary.'

interface Props {
  rating: number
  condition: number
  cleanliness: number
  service: number
  reviewCount: number
}

const props = defineProps<Props>()

const roundedRating = computed(() => {
  return roundRating(props.rating)
})

const roundedRatingOneDecimal = computed(() => {
  return Math.round(props.rating * 10) / 10
})

const roundedCondition = computed(() => {
  return Math.round(props.condition * 10) / 10
})

const roundedCleanliness = computed(() => {
  return Math.round(props.cleanliness * 10) / 10
})

const roundedService = computed(() => {
  return Math.round(props.service * 10) / 10
})

// function toggleDetails() {
//   this.showDetails = !this.showDetails
// }
</script>
